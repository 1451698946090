import React from "react"
import MainLayout from "../components/layout/mainLayout"
import { resumeData } from "../data/resumeData"
import FullCV from "../assets/AdrienneBoris_CV_FULL.pdf"

const Resume = props => {
  return (
    <>
      <MainLayout>
        <main className="Resume">
          <p className="Resume-header Resume--bold">
            Here’s just a partial list of some of my favorite directing
            projects. For more detailed information please see my <a href={FullCV} target="_blank">complete CV</a> of directing,
            teaching, producing, and administration
          </p>
          <h3 className="Resume-subHeader">Select Directing, Full Productions</h3>
          <ul className="Resume-listWrapper">
            {resumeData.selectDirecting.map(data => (
              <li className="Resume-listItem">
                <p className="Resume-listItem--title">{data.show}</p>
                <p className="Resume-listItem--location">{data.location}</p>
              </li>
            ))}
          </ul>
          <h3 className="Resume-subHeader">Select Staged Reading, New Work Development</h3>
          <ul className="Resume-listWrapper">
            {resumeData.selectStagedReading.map(data => (
              <li className="Resume-listItem">
                <p className="Resume-listItem--title">{data.show}</p>
                <p className="Resume-listItem--location">{data.location}</p>
              </li>
            ))}
          </ul>
          <h3 className="Resume-subHeader">
          Selected Assistant Directing
          </h3>
          <ul className="Resume-listWrapper">
            {resumeData.assistantDirecting.map(data => (
              <li className="Resume-listItem">
                <p className="Resume-listItem--title">{data.show}</p>
                <p className="Resume-listItem--location">{data.location}</p>
              </li>
            ))}
          </ul>
          <h3 className="Resume-subHeader">
            Education, Training &amp; Professional Affiliations
          </h3>
          <ul className="Resume-listWrapper">
            {resumeData.education.map(data => (
              <li className="Resume-listItem">
                {!data.text ? (
                  <p>{data}</p>
                ) : (
                  <p>
                    Executive Producer & Stage Director,{" "}
                    <a href="https://www.lowellhouseopera.com" target="_blank">
                      Lowell House Opera at Harvard College
                    </a>{" "}
                    (current)
                  </p>
                )}
              </li>
            ))}
          </ul>
        </main>
      </MainLayout>
    </>
  )
}

export default Resume

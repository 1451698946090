import React from 'react';

export const resumeData = {
  selectDirecting: [
{show: "Il barbiere di Siviglia (upcoming)",	location: "Long Island Lyric Opera, 2021"},
{show: "Sweeney Todd (COVID-19)",location: "Lowell House Opera, 2020"},
{show: "Burst, Amy Leigh Horan", location: "Chelsea Theatre Works, 2019"},
{show: "A Lady Does Not Scratch Her Crotch, Celeste Cahn", location: "Central Square Theater, 2019"},
{show: "La scola degli amanti, ossia: Così fan tutte", location: "Lowell House Opera, 2019 "},
{show: "Absent Grace, a 10-minute opera, Marti Epstein",	location: "Boston Opera Collaborative, 2019 "},
{show: "DIVAS: A New Play with Opera Music, Laura Neill", location: "OperaHub, 2018"},
{show: "Symposium, a 10-minute opera, Oliver Caplan",	location: "Boston Opera Collaborative, 2018"},
{show: "La bohème",	location: "Long Island Lyric Opera, 2017"},
{show: "Next to Normal",	location: "Arts After Hours, 2017"},
{show: "OR, Liz Duffy Adams", location: "Chelsea Theatre Works, 2017"},
{show: "American Flag, a 10-minute opera, Eva Kendrick",	location: "Boston Opera Collaborative, 2017"},
{show: "Baltimore, Kirsten Greenidge (tour)",location: "New Repertory Theatre/BCAP, 2016"},
{show: "The Merry Way, Bryn Boice (co-dir)",location: "Anthem Theatre Co., 2016"},
{show: "The Last 5 Years",	location: "Arts After Hours, 2016"},
{show: "La bohème",	location: "NEMPAC Opera Project, 2015"},
{show: "In the next room (or the vibrator play), Sarah Ruhl",	location: "Boston University MFA Thesis, 2015"},
{show: "She Eats Apples, Stephanie Brownell", location: "Boston Playwrights’ Theatre/Boston University, 2015"},
{show: "Die Fledermaus",	location: "NEMPAC Opera Project, 2014"},
{show: "The Mikado",	location: "Opera Providence, 2014"},
{show: "Romeo & Juliet, Shakespeare (all female)",location: "Femina Shakes, Boston University, 2013"},
{show: "Little Women",	location: "Opera North, 2013"},
{show: "Little Women",	location: "Opera del West, 2012"},
{show: "The Play About the Baby, Edward Albee",	location: "Exquisite Corps Theatre Co., 2012"},
{show: "Don Giovanni (semi-staged)",location: "MASSOpera, 2011"},
{show: "Bastien and Bastienne", location: "Opera Boston Underground @ First Night Boston, 2011"},
{show: "The Seven Deadly Sins",	location: "Opera Boston Underground @ Lizard Lounge, 2009"},
  ],
  selectStagedReading: [
    {show:"Mary Shelley, Helen Edmondson",location:"Central Square Theater, 2019"},
{show:"Chains of Providence, Marc Harpin",location:"Anthem Theatre Co., 2019"},
{show:"No One Talks About It, Cassie M. Seinuk",location:"Our Voices Festival, 2018"},
{show:"Spike Heels, Theresa Rebeck",location:"Maiden Phoenix Theatre Co., 2017"},
{show:"Why We Have Winter, Alexa Derman",location:"Fresh Ink Theatre, 2017"},
{show:"When Herod Came to Georgia, James McLindon",location:"New Repertory Theatre: Next Voices,	2016"},
  ],
  assistantDirecting: [
    {show:"Giulio Cesare, dir. Mary Birnbaum",location:"Boston Baroque, 2018"},
{show:"Ring Twice for Miranda, Alan Hruska, dir. Rick Lombardo",location:"Off-Broadway: Red Horse Prod. @NY City Center, 2018"},
{show:"Fiddler on the Roof, dir. Austin Pendleton",location:"New Repertory Theatre, 2017"},
{show:"Baltimore, Kirsten Greenidge, dir. Elaine Vaan Hogue",location:"New Repertory Theatre/BCAP, 2016"},
{show:"South Pacific, dir. Gabriel Barre",location:"Opera North, 2013"},
{show:"The Luck of the Irish, Kirsten Greenidge, dir. Melia Bensussen",location:"The Huntington Theatre Company, 2012"},
  ],

  education: [
    "Boston University CFA, School of Theatre MFA Directing, 2015 | Kenyon College, BA English and Theater, (2007)",
    "Exec. Producer & Stage Director, Lowell House Opera at Harvard University (2019-)",
    "Co-Producer & Artistic Associate, OperaHub (2015-2018)",
    "National New Play Network Producer-in-Residence, New Repertory Theatre (2015-2016)",
    "Artistic Intern, Signature Theatre Company, NYC (2015)",
    "Young Artist Stage Director, Opera North (2013)",
  ],
}
